import {isEmpty} from 'lodash';
import {connect, AnyIfEmpty} from 'react-redux';
import React, {Component, ReactElement} from 'react';
import {Helmet} from 'react-helmet-async';
import Cents from 'goodeggs-money';

import moment from 'infra/moment';
import Alert from 'web/components/alert';
import MarketLayout from 'web/components/market_layout';
import LoadingOverlay from 'web/components/loading_overlay';
import basketDuck from 'web/helpers/basket_duck';
import SubscriptionInfoModal from 'web/components/subscription_info_modal';
import DisplayTotals from 'web/components/totals';
import {humanDayOfWeek} from 'infra/formatters/time';
import {reducer as signInFlowReducer} from 'web/components/sign_in_flow/duck';
import {formatPromoCode} from 'infra/formatters/money';
import {actions as modalActions} from 'web/helpers/modal_duck';
import {actions as signUpModalActions} from 'web/helpers/sign_up_modal_duck';
import Metrics from 'infra/metrics/client';
import {ClientSettingsContext} from 'web/hooks/useClientSettings';
import {UiMarketProduct} from 'web/helpers/serializers/product';
import {UpcomingPreorderPeriod} from 'web/components/market_layout/store_builder';
import getIsUnavailableDeliveryWithinZip from 'domain/catalog/products/helpers/availability_helpers/get_is_unavailable_delivery_within_zip';
import HomeBanner from 'web/components/home_banner';

import CheckoutErrors from '../components/checkout_errors';
import AddToOrderControls from './components/add_to_order_controls';
import AttendedDeliveryWarning from './components/attended_delivery_warning';
import BasketItem from './components/basket_item';
import CheckoutButtons from './components/checkout_buttons';
import EmptyBasket from './components/empty_basket';
import FulfillmentDayDropdown from './components/fulfillment_day_dropdown';
import FulfillmentOfferDropdown from './components/fulfillment_offer_dropdown';
import MasqueradeOverrides from './components/masquerade_overrides';
import SanitizationChanges from './components/sanitization_changes';
import * as basketPageDuck from './duck';
import {BannerMinOrder} from './components/banner_min_order';
import UnavailableItemsList from './components/unavailable_items_list';
// import PreorderInfoModal from './components/preoder_info_modal';

export interface ProductId {
  id: string;
}

export interface Item {
  bottleDeposit: number;
  hasBottleDeposit: boolean;
  subscriptionDiscount: number;
  subtotalMinusSubscriptionDiscount: number;
  subtotal?: number;
  basetotal: number;
  id: string;
  quantity: number;
  shouldSubscribe: boolean;
  product: ProductId;
}

export interface Link {
  text: string;
  url: string;
}

export interface Info {
  title: string;
  body: string;
  link?: Link;
}

export interface DisplayTotal {
  key: string;
  label: string;
  amount: number;
  formattedAmount: string;
  info?: Info;
  alert?: string;
  secondaryLabel?: string | ReactElement;
  meta?: Record<string, any>;
  discountValues?: {discountKey: string; discountValue: number};
}

export interface LineItem {
  id: string;
  subtotal: number;
  basetotal: number;
  subtotalMinusSubscriptionDiscount: number;
  subscriptionDiscount: number;
  bottleDeposit: number;
}

export interface LineItemTotals {
  [key: string]: LineItem;
}

export interface Totals {
  displayTotals: DisplayTotal[];
  subscriptionDiscount: number;
  subtotal: number;
  promoDiscount: number;
  referralDiscount: number;
  deliveryFee: number;
  bottleDeposit: number;
  tax: number;
  tip: number;
  storeCredit: number;
  totalDue?: number;
  orderMinimum: number;
  amountToMinimum: number;
  newOrderOrderMinimum: number;
  newOrderAmountToMinimum: number;
  lineItemTotals: LineItemTotals;
  totalSavings: number;
}

export interface Sms {
  requiresUserAction: boolean;
  subscribed: boolean;
}

export interface PromptEmails {
  subscribed: boolean;
}

export interface Membership {
  isMember: boolean;
  startDate: string;
  endDate?: string | null;
}

export interface User {
  firstName: string;
  lastName?: string;
  email: string;
  phone: string;
  newsletter: boolean;
  sms: Sms;
  promptEmails: PromptEmails;
  substitutions: boolean;
  id: string;
  orderCount: number;
  hasPassword: boolean;
  masquerading: boolean;
  referralId: string;
  availableBalance: number;
  membership?: Membership;
}

export interface PreorderPeriod {
  preorderDays: string[];
  name: string;
}

export interface Session {
  id: string;
}

export interface App {
  name: string;
  instance: string;
}

export interface Basket {
  _id: string;
  totals?: Totals;
  items: Item[];
  promoCodes?: PromoCode[];
  referralGift?: string;
}

export interface Subcategory {
  id: string;
  name: string;
  url: string;
  pageUrl: string;
}

export interface Category {
  id: string;
  name: string;
  shortName: string;
  url: string;
  hidden: boolean;
  subcategories: Subcategory[];
}

export interface Location {
  name: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
}

export interface Foodhub {
  name: string;
  slug: string;
  tzid: string;
  isActive: boolean;
  isFulfillmentCenter: boolean;
  isProductionCenter: boolean;
  location: Location;
}

export interface FulfillmentDay {
  day: string;
  status: string;
  cutoffDate: Date;
  isDefault: boolean;
  preorderPeriodIds: string[];
}

export interface FulfillmentDaysByDay {
  [key: string]: FulfillmentDay;
}
export interface Referrals {
  kickbackValue: number;
  giftValue: number;
}

export interface Vendor {
  city: string;
  name: string;
  shortDescription: string;
  slug: string;
  thumbnailUrl: string;
  url: string;
}

export interface Availability {
  status: string;
  quantity: number;
}

export interface AvailabilitiesByDay {
  [key: string]: Availability;
}

export interface CheckoutError {
  message?: string;
  type?: string;
}

export interface Products {
  [key: string]: UiMarketProduct;
}

export interface FulfillmentOffer {
  offer: Offer;
  statusText: string;
  status: string;
  label: string;
  hasUnavailableProducts: boolean;
  orderIds: string[];
  hasPreorderWindows: boolean;
}

export interface PotentialDayDetail {
  day: string;
  label: string;
  description: string;
  availability: string;
}

export interface Offer {
  startAt?: Date;
  endAt?: Date;
  expiresAt?: Date;
  cutoffDate?: Date;
  status?: string;
  foodhubSlug?: string;
  feeCents?: number;
  signature?: string;
}

export interface MasqueradeOverridesType {
  ignoreMinimumOrder: boolean;
  dontSendConfirmation: boolean;
  ignoreCapacities: boolean;
}

export interface Settings {
  googleAuthenticateClientId: string;
  shoppingDaysLength: number;
}

export interface Navigation {
  isNavigating: boolean;
}

export interface SelectedFulfillmentOffer {
  offer?: Offer;
  statusText?: string;
  status?: string;
  label?: string;
  hasUnavailableProducts?: boolean;
  orderIds?: string[];
  hasPreorderWindows?: boolean;
}

export interface Error {
  message: string;
  heading: string;
}

export interface PromoCode {
  code: string;
  label?: string;
  value: number;
  type: 'dollar' | 'percent';
}

export interface SanitizationChange {
  issue: string;
  productName: string;
  message?: string;
  before?: string;
  after?: string;
  promoCode?: PromoCode;
}

export interface DeliveryWindow {
  startAt: string;
  endAt: string;
}

export interface UpcomingOrder {
  id: string;
  hasSubscribedItems: boolean;
  fulfillmentDay: string;
  deliveryDetails: {
    canLeaveAtDoor: boolean;
  };
  deliveryWindow: DeliveryWindow;
}

export interface TotalsTracking {
  addingToOrder: boolean;
  userId?: string;
  credits?: number;
  delivery?: number;
  deliveryDay?: string;
  orderCount?: number;
  pageName?: string;
  productCount: number;
  products?: Array<{
    id: string;
    name?: string;
    quantity: number;
    price: number;
  }>;
  itemCount?: number;
  subtotal: number;
  revenue: number;
  total: number;
  isMembershipOrder: boolean;
}

export interface ActivePreorderPeriod {
  name: string;
}

interface DispatchProps {
  openDayChooserModal: (preorderPeriod: UpcomingPreorderPeriod) => void;
  handleShowSubscriptionModal: (showDiscountContent: boolean) => void;
  handleDismissSubscriptionModal: () => void;
  handleChangeDay: (day: string) => void;
  handleSaveMasquerradeOverrides: (masqueradeOverrides: MasqueradeOverridesType) => void;
  handleEditQuantity: (args: {productId: string; quantity: number}) => void;
  handleSetShouldSubscribe: (args: {
    productId: string;
    shouldSubscribe: boolean;
    existingSubscriptionId: string | undefined;
  }) => void;
  handleChangeFulfillmentOffer: (fulfillmentOffer: FulfillmentOffer) => void;
  handleAddToOrderIdChange: (addToOrderId: string) => void;
  handleConfirmAddToOrder: () => void;
  handleAuthFlow: () => void;
}

export interface BasketProps extends DispatchProps {
  addToOrderId?: string;
  basket?: Basket;
  categories?: Category[];
  checkoutError?: CheckoutError;
  showSubscriptionModal?: boolean;
  showDiscountContent?: boolean;
  masqueradeOverrides: MasqueradeOverridesType;
  currentFulfillmentDay?: string;
  fulfillmentDaySummaries: FulfillmentDay[];
  fulfillmentDaysByDay: FulfillmentDaysByDay;
  canCreateNewOrder?: boolean;
  error?: Error;
  upcomingPreorderPeriods?: UpcomingPreorderPeriod[];
  features?: string[];
  fulfillmentOffers?: FulfillmentOffer[];
  isBelowMinimumForNewOrder?: boolean;
  isLoading?: boolean;
  isMasquerading?: boolean;
  items?: Item[];
  potentialDayDetails?: PotentialDayDetail[];
  potentialPreorderPeriodDetails: UpcomingPreorderPeriod[];
  products?: Products;
  sanitizationChanges?: SanitizationChange[];
  selectedFulfillmentOffer?: SelectedFulfillmentOffer;
  totals?: Totals;
  upcomingOrders?: UpcomingOrder[];
  user?: User;
  isAttendedDeliveryRequired?: boolean;
  activePreorderPeriod?: ActivePreorderPeriod;
  settings?: Settings;
  deliveryRestrictedMinimumWindowStart: number;
  accounting: {orderMinimum: number};
  zipCode: string;
}

interface BasketPageState {
  showAttendedDeliveryWarning: boolean;
}

const segmentFeature = 'basket';

class BasketPage extends Component<BasketProps, BasketPageState> {
  public static contextType = ClientSettingsContext;
  public context!: React.ContextType<typeof ClientSettingsContext>;

  public static pageName: string;
  public constructor(props: BasketProps) {
    super(props);
    this.state = {
      showAttendedDeliveryWarning: false,
    };
  }

  public static reducer(
    state?: Record<string, unknown>,
    action?: Record<string, unknown>,
  ): unknown {
    const newState = basketPageDuck.reducer(
      // TODO: (@shermam) This whole file is re-declaring a bunch of thigs
      // that are now correctly typed in the MarketLayoutStore. Note that
      // this reducer itself was using tha hacky `as unknown as ...`
      // We should convert this to use the type `PageType<StoreData>`
      // importing the `StoreData` type from the TSifyied version of the controller
      // See src/web/account/credit_card_page/index.tsx as an exmplae
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      MarketLayout.reducer(state, action) as unknown as {isLoading: boolean},
      action,
    );
    return signInFlowReducer(newState, action);
  }

  public componentDidMount(): void {
    const {user, totals, currentFulfillmentDay, items, basket} = this.props;
    const [availableItems, unavailableItems] = this.getAvailableAndUnavailableItems();
    (window as Window & typeof globalThis & {metrics: Metrics}).metrics.track('Cart Viewed', {
      userId: user == null ? null : user.id,
      basketId: basket?._id,
      fulfillmentDay: currentFulfillmentDay,
      products:
        items == null
          ? []
          : items.map(({product, quantity, subtotal}) => ({
              productId: product.id,
              quantity,
              subtotal,
            })),
      availableProducts: availableItems.map(({product}) => product.id),
      unavailableProducts: unavailableItems.map(({product}) => product.id),
      subscriptionDiscount: totals == null ? null : totals.subscriptionDiscount,
      subtotal: totals == null ? null : totals.subtotal,
      promoDiscount: totals == null ? null : totals.promoDiscount,
      referralDiscount: totals == null ? null : totals.referralDiscount,
      deliveryFee: totals == null ? null : totals.deliveryFee,
      bottleDeposit: totals == null ? null : totals.bottleDeposit,
      storeCredit: totals == null ? null : totals.storeCredit,
    });
  }

  public getAvailableAndUnavailableItems(): [Item[], Item[], Item[], Item[]] {
    const clientSettings = this.context;

    const availableItems: Item[] = [];
    const unavailableForDeliveryItems: Item[] = [];
    const unavailableForMorningDeliveryItems: Item[] = [];
    const unavailableItems: Item[] = [];

    let shouldDeliveryBeRestricted = true; // if no ffo is selected, we do not show restricted items as available
    if (
      Boolean(this.props.selectedFulfillmentOffer) &&
      Boolean(this.props.selectedFulfillmentOffer?.offer)
    ) {
      const localTime = moment.tz(
        this.props.selectedFulfillmentOffer?.offer?.startAt,
        clientSettings.tzid ?? '',
      );
      shouldDeliveryBeRestricted =
        localTime.hours() < this.props.deliveryRestrictedMinimumWindowStart;
    }
    this.props.items?.forEach((item) => {
      const product = this.props.products?.[item.product.id];
      const availabilitySummary: Availability | undefined =
        product?.availabilitiesByDay[this.props.currentFulfillmentDay ?? 0];
      if (
        getIsUnavailableDeliveryWithinZip({
          zipCode: this.props.zipCode,
          isUnavailableForDeliveryLa: product?.isUnavailableForDeliveryLa,
          isUnavailableForDeliverySfBay: product?.isUnavailableForDeliverySfBay,
        })
      ) {
        unavailableForDeliveryItems.push(item);
      } else if (Boolean(product?.isDeliveryRestricted) && shouldDeliveryBeRestricted) {
        unavailableForMorningDeliveryItems.push(item);
      } else if (Boolean(availabilitySummary) && availabilitySummary?.status === 'available') {
        availableItems.push(item);
      } else {
        unavailableItems.push(item);
      }
    });
    return [
      availableItems,
      unavailableItems,
      unavailableForDeliveryItems,
      unavailableForMorningDeliveryItems,
    ];
  }

  public closeAttendedDeliveryWarning(): void {
    this.setState({showAttendedDeliveryWarning: false});
  }

  public openDayChooserModal(preorderPeriod: UpcomingPreorderPeriod): void {
    this.props.openDayChooserModal(preorderPeriod);
  }

  public handleAuthFlow(): void {
    this.props.handleAuthFlow();
  }

  public handleStartAddToOrder(): void {
    const upcomingOrder = this.props.upcomingOrders?.find(({id}) => id === this.props.addToOrderId);
    if (
      Boolean(this.props.isAttendedDeliveryRequired) &&
      Boolean(
        (upcomingOrder as unknown as UpcomingOrder | undefined)?.deliveryDetails.canLeaveAtDoor,
      )
    ) {
      this.setState({showAttendedDeliveryWarning: true});
    } else {
      this.props.handleConfirmAddToOrder();
    }
  }

  public getCheckoutProperties(): TotalsTracking {
    const {items, user, totals, products, basket} = this.props;

    return {
      addingToOrder: false,
      userId: user?.id,
      credits: totals?.storeCredit,
      delivery: totals?.deliveryFee,
      deliveryDay: this.props.currentFulfillmentDay,
      orderCount: user?.orderCount,
      pageName: BasketPage.pageName,
      productCount: items?.length ?? 0,
      products: (items ?? []).map(({product, quantity, subtotal}) => ({
        id: product.id,
        name: products?.[product.id].name,
        quantity,
        price: new Cents(subtotal ?? 0).toDollars(),
      })),
      itemCount: items?.length,
      subtotal: new Cents(totals?.subtotal ?? 0).toDollars(),
      revenue: new Cents(basket?.totals?.totalDue ?? 0).toDollars(),
      total: new Cents(basket?.totals?.totalDue ?? 0).toDollars(),
      isMembershipOrder: Boolean(user?.membership?.isMember),
    };
  }

  public isEligibleToCheckout(items: Item[]): boolean {
    const basketIsEmpty: boolean = isEmpty(this.props.items);
    if (basketIsEmpty) return false;

    const noSubscriptionsAreSelected: boolean = items.every((item) => !item.shouldSubscribe);

    if (noSubscriptionsAreSelected) return true;

    const total = this.sumItemSubtotal(items);

    return total >= this.props.accounting.orderMinimum;
  }

  private hasSubscribedItemsInUpcomingOrder(): boolean {
    const hasSubscribedItemsInUpcomingOrder = this.props.upcomingOrders?.some((upcomingOrder) => {
      if (upcomingOrder.id === this.props.addToOrderId) {
        if (upcomingOrder.hasSubscribedItems) {
          return true;
        }
      }
      return false;
    });

    return hasSubscribedItemsInUpcomingOrder ?? false;
  }

  public canAddSubscribedItemsUpcomingOrder(
    isEligibleToCheckout: boolean,
    hasSubscribedItemsInUpcomingOrder: boolean,
  ): boolean {
    if (hasSubscribedItemsInUpcomingOrder) return true;

    if (isEligibleToCheckout && !hasSubscribedItemsInUpcomingOrder) return true;

    if (!isEligibleToCheckout && !hasSubscribedItemsInUpcomingOrder) return false;

    return false;
  }

  public getBasketSubscriptionTotal(items: Item[]): number {
    const basketIsEmpty: boolean = items.length === 0;
    if (basketIsEmpty) return 0;

    const total = this.sumItemSubtotal(items);
    return total;
  }

  private sumItemSubtotal(items: Item[]): number {
    let total = 0;

    items.forEach((item) => {
      if (item.shouldSubscribe) {
        if (item.subtotal !== undefined) {
          total += item.subtotal;
        }
      }
    });

    return total;
  }

  public renderBasket(): React.ReactNode {
    const clientSettings = this.context;
    const [
      availableItems,
      unavailableItems,
      unavailableForDeliveryItems,
      unavailableForMorningDeliveryItems,
    ] = this.getAvailableAndUnavailableItems();

    const commonProps = {
      currentFulfillmentDay: this.props.currentFulfillmentDay,
      clientSettings,
      products: this.props.products,
      fulfillmentDaySummaries: this.props.fulfillmentDaySummaries,
      upcomingPreorderPeriods: this.props.upcomingPreorderPeriods,
      fulfillmentDaysByDay: this.props.fulfillmentDaysByDay,
      handleEditQuantity: this.props.handleEditQuantity,
      deliveryRestrictedMinimumWindowStart: this.props.deliveryRestrictedMinimumWindowStart,
      zipCode: this.props.zipCode,
    };

    const promoCode = this.props.basket?.promoCodes?.[0];
    const referralGift = this.props.basket?.referralGift;

    // In the case that we we have a fulfillment
    // day that is outside of the shopping days boundary and there is no active
    // preorder period, we should not allow a user too re-select their fulfillment
    // day or fulfillment offer
    const lastDayOfUpComingShoppingDays = moment()
      .add((this.props.settings?.shoppingDaysLength ?? 0) - 1, 'days')
      .format('YYYY-MM-DD');

    const isShoppingInPreorderPeriod =
      this.props.upcomingPreorderPeriods?.some(
        (preorderPeriod) =>
          Boolean(this.props?.currentFulfillmentDay) &&
          (this.props?.currentFulfillmentDay ?? '') >= preorderPeriod.startDay &&
          (this.props?.currentFulfillmentDay ?? '') <= preorderPeriod.endDay,
      ) ?? false;
    const isShoppingInPhantomDay =
      (this.props?.currentFulfillmentDay ?? '') > lastDayOfUpComingShoppingDays &&
      !isShoppingInPreorderPeriod;

    const hasSubscribedItemsInUpcomingOrder = this.hasSubscribedItemsInUpcomingOrder();
    const isEligibleToCheckout = this.isEligibleToCheckout(availableItems);
    const basketSubscriptionTotal = this.getBasketSubscriptionTotal(availableItems);
    const canAddSubscribedItemsUpcomingOrder = this.canAddSubscribedItemsUpcomingOrder(
      isEligibleToCheckout,
      hasSubscribedItemsInUpcomingOrder,
    );
    const hasHorizonWithinPreOrders = Boolean(
      this.props.currentFulfillmentDay && clientSettings.shoppingDaysLengthPerDates
        ? clientSettings.shoppingDaysLengthPerDates[this.props.currentFulfillmentDay]
        : false,
    );
    return (
      <div className="basket-page basket-view__fulfillments">
        <div className="basket-page__content fulfillment">
          {(this.props.isLoading ?? false) && <LoadingOverlay />}
          {Boolean(this.props.showSubscriptionModal) && (
            <SubscriptionInfoModal
              showDiscountContent={this.props.showDiscountContent}
              onDismiss={this.props.handleDismissSubscriptionModal}
            />
          )}
          <div className="basket-view__fulfillment-header">
            <div className="header-cell">
              <i className="icon icon-truck basket-view__delivery-header-icon" />
            </div>
            {isShoppingInPhantomDay ? (
              <div className="header-cell">No delivery windows available</div>
            ) : (
              <>
                <div className="header-cell">
                  <FulfillmentDayDropdown
                    dropdownDays={this.props.potentialDayDetails}
                    dropdownPreorderPeriods={this.props.potentialPreorderPeriodDetails}
                    currentFulfillmentDay={this.props.currentFulfillmentDay}
                    upcomingPreorderPeriods={this.props.upcomingPreorderPeriods}
                    onClickPreorderPeriod={this.openDayChooserModal.bind(this)}
                    onChangeDay={this.props.handleChangeDay}
                    fulfillmentDaySummaries={this.props.fulfillmentDaySummaries}
                    showPreorderPeriods={
                      !hasHorizonWithinPreOrders &&
                      Boolean(this.props.upcomingPreorderPeriods?.length ?? -1 > 0)
                    }
                  />
                </div>
                <div className="header-cell">
                  {isEmpty(this.props.addToOrderId) && (
                    <FulfillmentOfferDropdown
                      fulfillmentOffers={this.props.fulfillmentOffers}
                      selectedFulfillmentOffer={this.props.selectedFulfillmentOffer}
                      onChangeFulfillmentOffer={this.props.handleChangeFulfillmentOffer}
                    />
                  )}
                </div>
              </>
            )}
          </div>
          <div className="raised-tile__body">
            <div className="js-basket-items">
              {availableItems.map((item) => {
                const product = this.props.products?.[item.product.id];
                return (
                  <BasketItem
                    item={item}
                    availableQuantity={
                      product?.availabilitiesByDay[this.props?.currentFulfillmentDay ?? ''].quantity
                    }
                    onEditQuantity={this.props.handleEditQuantity}
                    onSetShouldSubscribe={this.props.handleSetShouldSubscribe}
                    onShowSubscriptionModal={this.props.handleShowSubscriptionModal}
                    hasBottleDeposit={item.hasBottleDeposit}
                    key={`${product?.id ?? ''}`}
                    product={product}
                    quantity={item.quantity}
                    subtotal={item.subtotal}
                    basetotal={item.basetotal}
                    subscriptionDiscount={item.subscriptionDiscount}
                    subtotalMinusSubscriptionDiscount={item.subtotalMinusSubscriptionDiscount}
                    basketSubscriptionTotal={basketSubscriptionTotal}
                  />
                );
              })}
            </div>
          </div>
          {unavailableItems.length > 0 && (
            <UnavailableItemsList
              {...commonProps}
              items={unavailableItems}
              header={`Not Available ${humanDayOfWeek(
                this.props.currentFulfillmentDay,
                clientSettings.tzid,
              )}`}
            />
          )}

          {unavailableForDeliveryItems.length > 0 && (
            <UnavailableItemsList
              {...commonProps}
              items={unavailableForDeliveryItems}
              header="Not available for delivery within your zone"
            />
          )}

          {unavailableForMorningDeliveryItems.length > 0 && (
            <UnavailableItemsList
              {...commonProps}
              items={unavailableForMorningDeliveryItems}
              header="These products are only available for select afternoon windows"
            />
          )}

          {(unavailableItems.length === 0 ||
            unavailableForDeliveryItems.length === 0 ||
            unavailableForMorningDeliveryItems.length === 0) && (
            <hr
              className="basket-view__everything-available-separator"
              data-testid="unavailableItemsSeparator"
            />
          )}

          <div className="basket-view__fulfillment-footer">
            <DisplayTotals
              displayTotals={this.props.basket?.totals?.displayTotals}
              showDeliveryFee={this.props.selectedFulfillmentOffer?.offer != null}
              isMembershipOrder={this.props.user?.membership?.isMember}
              totalSavings={this?.props.basket?.totals?.totalSavings}
            />

            <div className="basket-view__checkout">
              {Boolean(this.props.addToOrderId) && (
                <AddToOrderControls
                  addToOrderId={this.props.addToOrderId}
                  currentFulfillmentDay={this.props.currentFulfillmentDay}
                  tzid={clientSettings.tzid}
                  upcomingOrders={this.props.upcomingOrders}
                  onAddToOrderIdChange={this.props.handleAddToOrderIdChange}
                  onAddToOrder={this.handleStartAddToOrder.bind(this)}
                  enableAddToOrder={
                    !((this.props.totals?.subtotal ?? 0) > 0) || !canAddSubscribedItemsUpcomingOrder
                  }
                />
              )}
              {(this.props.canCreateNewOrder ?? false) && (
                <CheckoutButtons
                  addingToOrder={Boolean(this.props.addToOrderId)}
                  checkoutStartedProperties={this.getCheckoutProperties()}
                  categories={this.props.categories}
                  currentFulfillmentDay={this.props.currentFulfillmentDay}
                  isBelowMinimumForNewOrder={this.props.isBelowMinimumForNewOrder}
                  newOrderOrderMinimum={this.props.totals?.newOrderOrderMinimum}
                  isShoppingInPhantomDay={isShoppingInPhantomDay}
                  user={this.props.user}
                  features={this.props.features}
                  isEligibleToCheckout={isEligibleToCheckout}
                  handleAuthFlow={this.handleAuthFlow.bind(this)}
                />
              )}
              <div className="basket-view__security-promise">
                <i className="icon icon-lock basket-view__secure-lock" />
                <div className="basket-view__secure-label">Secure Checkout</div>
              </div>
            </div>
            {(isEmpty(promoCode) || Boolean(referralGift)) && !isEmpty(this.props.addToOrderId) ? (
              <div className="basket-view__promocode-section">
                <hr />
                <span className="basket-view__promocode-label">
                  Promo Code:
                  {isEmpty(promoCode) && promoCode != null
                    ? formatPromoCode(promoCode)
                    : 'Referral'}
                </span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  public render(): React.ReactNode {
    const isEligibleToCheckout = this.isEligibleToCheckout(
      this.getAvailableAndUnavailableItems()[0],
    );

    return (
      <MarketLayout disableBasketDropdown>
        <Helmet>
          <title>Basket | Good Eggs</title>
          <meta
            name="google-signin-client_id"
            content={this.props.settings?.googleAuthenticateClientId}
          />
        </Helmet>

        {this.state.showAttendedDeliveryWarning && (
          <AttendedDeliveryWarning
            onClose={this.closeAttendedDeliveryWarning.bind(this)}
            onConfirmAddToOrder={this.props.handleConfirmAddToOrder}
          />
        )}
        <div className="basket-page">
          {!isEmpty(this.props.items) && !isEligibleToCheckout && <BannerMinOrder />}
          <div className="basket-view js-basket-view">
            {this.props.checkoutError != null && <CheckoutErrors {...this.props.checkoutError} />}
            {this.props.error != null && (
              <div className="basket-view__warning">
                <Alert type="warning" heading={this.props.error.heading}>
                  {this.props.error.message}
                </Alert>
              </div>
            )}
            <div className="basket-view__header">
              {/* {this.props.activePreorderPeriod != null && (
                <PreorderInfoModal name={this.props.activePreorderPeriod.name} />
              )} */}
              <HomeBanner />
              <div className="basket-view__page-title">Your Basket</div>
              <div className="basket-view__header-delivery-options js-delivery-info-link">
                Delivery Info
              </div>
            </div>
            <SanitizationChanges sanitizationChanges={this.props.sanitizationChanges} />
            {this.props.isMasquerading != null && this.props.isMasquerading ? (
              <MasqueradeOverrides onSubmit={this.props.handleSaveMasquerradeOverrides} />
            ) : null}
            {(this.props.items?.length ?? 0) > 0 ? (
              this.renderBasket()
            ) : (
              <EmptyBasket categories={this.props.categories || []} />
            )}
          </div>
        </div>
      </MarketLayout>
    );
  }
}

BasketPage.pageName = 'Basket';

// TODO: (guilherme-vp) TSify basket_page/controller.js and use its Store interface
export function mapStateToProps(state: AnyIfEmpty<any>): unknown {
  const activePreorderPeriod = state.upcomingPreorderPeriods?.find(
    (preorder: UpcomingPreorderPeriod) =>
      preorder.preorderDays.includes(state.currentFulfillmentDay),
  );

  const existingSubscriptionOrder = state.upcomingOrders?.find(
    (order: UpcomingOrder) => order.id === state.addToOrderId,
  );

  const {totals} = state.basket;
  const {lineItemTotals} = totals;
  let {items} = state.basket;
  if (lineItemTotals != null) {
    items = items.map((item: Item) => {
      const {
        subtotal,
        total,
        bottleDeposit,
        subscriptionDiscount,
        subtotalMinusSubscriptionDiscount,
        basetotal,
      } = lineItemTotals[item.id] ?? {};
      return {
        ...item,
        bottleDeposit,
        hasBottleDeposit: Boolean(bottleDeposit != null && bottleDeposit > 0),
        existingSubscriptionId: existingSubscriptionOrder?.subscriptionId ?? undefined,
        subscriptionDiscount,
        subtotalMinusSubscriptionDiscount,
        subtotal,
        basetotal,
        total,
      };
    });
  }
  const canCreateNewOrder = state.fulfillmentOffers.some(
    (fulfillmentOffer: FulfillmentOffer) => fulfillmentOffer.status === 'available',
  );

  return {items, totals, canCreateNewOrder, activePreorderPeriod, ...state};
}

export function mapDispatchToProps(dispatch: (action: unknown) => void): DispatchProps {
  return {
    handleAuthFlow: (): void => {
      dispatch(
        modalActions.replaceModal({
          modal: 'SignUpModal',
          modalViewed: {
            // feature: segmentFeature,
            pageUrl: window.location.pathname,
            loggedIn: false,
            // ctaText: SignUpFormCta,
          },
        }),
      );
      dispatch(signUpModalActions.showSignUpModalFlow());
    },
    handleChangeDay: (day: string): void =>
      dispatch((_dispatch: unknown, getState: () => {currentFulfillmentDay: string}) => {
        const {currentFulfillmentDay} = getState();
        if (day !== currentFulfillmentDay) {
          dispatch(basketPageDuck.actions.updateFulfillmentDay(day));
        }
      }),
    openDayChooserModal: (preorderPeriod: UpcomingPreorderPeriod): void =>
      dispatch(
        modalActions.openDayChooserModal({
          preorderPeriod,
          redirectOnSelect: false,
          productAvailabilityDays: undefined,
        }),
      ),
    handleShowSubscriptionModal: (showDiscountContent: boolean): void =>
      dispatch(basketPageDuck.actions.showSubscriptionModal({showDiscountContent})),
    handleDismissSubscriptionModal: (): void =>
      dispatch(basketPageDuck.actions.hideSubscriptionModal()),
    handleSaveMasquerradeOverrides: (masqueradeOverrides: MasqueradeOverridesType): void => {
      const {ignoreMinimumOrder, dontSendConfirmation, ignoreCapacities} = masqueradeOverrides;
      dispatch(
        basketPageDuck.actions.updateMasqueradeOverrides(
          ignoreMinimumOrder,
          dontSendConfirmation,
          ignoreCapacities,
        ),
      );
    },
    handleEditQuantity: ({productId, quantity}: {productId: string; quantity: number}): void =>
      dispatch(
        basketDuck.actions.assignQuantity(
          {productId, quantity},
          {
            context: {feature: segmentFeature},
          },
        ),
      ),
    handleSetShouldSubscribe: ({
      productId,
      shouldSubscribe,
      existingSubscriptionId,
    }: {
      productId: string;
      shouldSubscribe: boolean;
      existingSubscriptionId: string | undefined;
    }): void =>
      dispatch(
        basketDuck.actions.setShouldSubscribe({productId, shouldSubscribe, existingSubscriptionId}),
      ),
    handleChangeFulfillmentOffer: (fulfillmentOffer: FulfillmentOffer): void =>
      dispatch(basketPageDuck.actions.updateFulfillmentOffer(fulfillmentOffer)),
    handleAddToOrderIdChange: (addToOrderId: string): void =>
      dispatch(basketPageDuck.actions.updateAddToOrderId(addToOrderId)),
    handleConfirmAddToOrder: (): void => dispatch(basketPageDuck.actions.addToOrder()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps as unknown)(BasketPage);
