// TODO(@shermam) We should avoid relying on global settings.
// As soon as we have a better solution to expose client side
// settings, we should come back here and update this file.
/* global settings */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {formatDate} from 'goodeggs-formatters';

import moment from 'infra/moment';
import Modal from 'web/components/modal';
import {orderCutoffTime, humanDayOfWeek} from 'infra/formatters/time';
import {FeatureFlags} from 'web/helpers/experiments/feature_flags';
import {ClientSettingsContext} from 'web/hooks/useClientSettings';

export default class DayChooserModal extends Component {
  static contextType = ClientSettingsContext;
  /**
   * @type {React.ContextType<typeof ClientSettingsContext>}
   */
  context;

  constructor(props) {
    super();
    this.state = {preorderPeriod: props.preorderPeriod};
  }
  onClickDay(day) {
    this.props.handleChangeDay(day, {
      datePreamble: `You are now adding items to your basket for `,
      dateString: `${humanDayOfWeek(day, this.props.tzid)}, ${moment(day).format('MMMM Do')}`,
      body: 'Product availability may vary based on the selected date.',
      cta: 'Start shopping',
      categoryId: this.state.preorderPeriod?.categoryId,
    });
  }

  onClickPreorderPeriod(preorderPeriod) {
    this.setState({preorderPeriod});
  }

  isSelected(day) {
    return this.props.currentFulfillmentDay === day;
  }

  getCutoffText(cutoffDate) {
    return `Checkout by ${orderCutoffTime(cutoffDate, settings.tzid)}`;
  }

  getDays() {
    let days;
    if (this.state.preorderPeriod != null)
      days = this.state.preorderPeriod.preorderDays
        .map((day) => this.props.fulfillmentDaysByDay[day])
        .filter((day) => Boolean(day));
    else days = this.props.fulfillmentDaySummaries;
    return days;
  }

  render() {
    const clientSettings = this.context;
    const hidePreorderDays =
      this.props.features?.includes(FeatureFlags.HidePreorderDaySelector) ||
      (this.props.currentFulfillmentDay &&
        clientSettings.shoppingDaysLengthPerDates &&
        Boolean(clientSettings.shoppingDaysLengthPerDates[this.props.currentFulfillmentDay]));

    return (
      <Modal className="refresh day-picker-modal" fullScreenMobile onClose={this.props.onClose}>
        <div>
          <div className="modal-title-bar">
            <div onClick={this.props.onClose} className="modal__close-button">
              <a href="#">
                <i className="icon icon-thin-x" />
              </a>
            </div>
            {this.state.preorderPeriod ? (
              <h3 className="modal-title-bar__title">Select Preorder Delivery Day</h3>
            ) : (
              <h3 className="modal-title-bar__title">Select a Delivery Day</h3>
            )}
          </div>
          <div className="modal-content preorder-modal">
            {this.getDays().map((obj) => (
              <button
                type="button"
                className={classNames('button-outline button--full-width', {
                  selected: this.isSelected(obj?.day),
                })}
                disabled={obj?.status !== 'available'}
                onClick={() => {
                  this.onClickDay(obj?.day);
                }}
                key={obj?.day}
              >
                {obj?.day ===
                formatDate(moment.tz(settings.tzid).toDate(), 'clockDate', settings.tzid)
                  ? `Today, ${this.getCutoffText(obj?.cutoffDate)}`
                  : moment(obj?.day).format('dddd, MMM D')}
              </button>
            ))}

            {!this.state.preorderPeriod &&
            (this.props.upcomingPreorderPeriods ?? []).length &&
            !hidePreorderDays ? (
              <a
                href="#"
                className="text-link"
                onClick={() => {
                  this.onClickPreorderPeriod(this.props.upcomingPreorderPeriods[0]);
                }}
              >
                Access Preorder
              </a>
            ) : null}
          </div>
        </div>
      </Modal>
    );
  }
}

DayChooserModal.propTypes = {
  features: PropTypes.arrayOf(PropTypes.string),
  preorderPeriod: PropTypes.shape({
    startDay: PropTypes.string.isRequired,
    preorderDays: PropTypes.array.isRequired,
    endDay: PropTypes.string.isRequired,
    firstPreorderDay: PropTypes.string.isRequired,
    categoryId: PropTypes.string,
    name: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
  handleChangeDay: PropTypes.func.isRequired,
  currentFulfillmentDay: PropTypes.string.isRequired,
  fulfillmentDaysByDay: PropTypes.object.isRequired,
  fulfillmentDaySummaries: PropTypes.array.isRequired,
  upcomingPreorderPeriods: PropTypes.array,
  tzid: PropTypes.string.isRequired,
  productAvailabilityDays: PropTypes.array,
  closeOnBack: PropTypes.bool,
  redirectOnSelect: PropTypes.bool,
};
